@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.borderedge {
  color: white;
  font-weight: 500;
  font-size: 18px;
  --c: white;
  /* the border color */
  --b: 2px;
  /* the border thickness*/
  --g: 5px;
  /* the gap on hover */

  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%, var(--c) 0;
  background:
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
  transition: .3s, background-position .3s .3s;
  cursor: pointer;
}

.borderedge:hover {
  --_i: 100%;
  transition: .3s, background-size .3s .3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* background-color: red; */
}
.active-page {
  border-bottom: 2px solid white !important;
  color: white !important;
}
.borderedge2 {
  --color: #FA6900;
  /* the color of the border */
  --border: 2px;
  /* the thickness of the border */
  --offset: 15px;
  /* control the movement of the border */
  --gap: 5px;
  /* the gap on hover */

  border-radius: 5px;
  cursor: pointer;

  border: var(--offset) solid #0000;
  --_m: radial-gradient(50% 50%, #000 calc(100% - var(--offset)), #0000 calc(100% - var(--border)));
  -webkit-mask: var(--_m);
  mask: var(--_m);
  --_g: #0000 calc(99% - var(--border)), var(--color) calc(100% - var(--border)) 99%, #0000;
  --_s: var(--offset);
  --_r: 100% 100% at;
  background:
    radial-gradient(var(--_r) 0 0, var(--_g)) calc(100% + var(--_s)) calc(100% + var(--_s)),
    radial-gradient(var(--_r) 100% 0, var(--_g)) calc(0% - var(--_s)) calc(100% + var(--_s)),
    radial-gradient(var(--_r) 0 100%, var(--_g)) calc(100% + var(--_s)) calc(0% - var(--_s)),
    radial-gradient(var(--_r) 100% 100%, var(--_g)) calc(0% - var(--_s)) calc(0% - var(--_s));
  background-size: 50% 50%;
  background-repeat: no-repeat;
  transition: .4s;
}

.borderedge2:hover {
  --_s: 0px;

}

.bg-smoke {
  background-color: whitesmoke;
}

body {
  background-color: white;
  font-family: 'Roboto', sans-serif !important;
}


.bg-orange {
  /* background-color: rgb(255, 81, 47) !important; */
background-color: #FF6243 !important;
}

@media screen and (max-width: 767px) {

  .homescl1 {
    height: 150px;
  }

  .curved-image-div {
    height: 150px !important;
  }
.curved-image{
background-position: left !important;
}
  .aboutus {
    flex-direction: column;
  }
}
.custom-ul {
  list-style-type: none; 

  padding: 0px;
}

.custom-ul li {
  padding-left: 20px; /* Add some padding to separate text from the image */
background-position: 0 center !important;
  background-size: 22px !important; /* Set the size of your image */
  margin-bottom: 5px; /* Optional: Adjust spacing between list items */
}
.custom-event-ul {
  list-style-type: none; 

  padding: 5px;
}

.custom-event-ul li {
  padding-left: 20px; /* Add some padding to separate text from the image */
background-position: 0 center !important;
background: url('../asset/images/other/hibiscus.png');
background-repeat: no-repeat;
  background-size: 22px !important; /* Set the size of your image */
  margin-bottom: 5px; /* Optional: Adjust spacing between list items */
}

.custom-event-ul li label{
margin-left: 5px;
padding: 5px;
margin-bottom: 10px;
}
.carousel-home{
  background:#fff8dc;
  border-radius: 10px;

}