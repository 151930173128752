


.container{
  position: relative;
}

.container .card{
  position: relative;
  width: 320px;
  height: 450px;

  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  backdrop-filter: blur(15px);
}

.container .card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1679AB;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
  
}

.container .card:hover:before{
  clip-path: circle(300px at 80% -20%);
}

.container .card:after{
  content: 'Nike';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(255,255,25,0.05)
}

.container .card .imgBx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
    
}

.container .card .imgBx img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  width: 270px;
}

.container .card .contentBx{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:hover .contentBx{
  height: 210px;
}

.container .card .contentBx h2{
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: black;
  margin: 0;
}

.container .card .contentBx .size, .container .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .size{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx .color{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .contentBx .size h3, .container .card .contentBx .color h3{
  color: black;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .size span{
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .contentBx .size span:hover{
  background: #1679AB;
}

.container .card .contentBx .color span{
  width: 20px;
  height: 20px;
  background: #ff0;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}


.container .card .contentBx a{
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.container .card:hover .contentBx a{
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
  
}

.card-body {
    position: relative; /* Adjust margin as needed */
    padding: 10px; /* Adjust padding as needed */
    margin:3px;
    border: 1px solid #ccc; /* Example border */
    border-radius: 10px; /* Example border radius */
    background-color: #fff; /* Example background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Example box shadow */
  }
  

  .curved-image-div {
    width: 250px; /* Adjust width as needed */
 /* Adjust height as needed */
 
height: auto;

  }
  .curved-image {
   
    width: 100%;
    height: 100%;
    background-size: contain;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-position: bottom;
    background-repeat: no-repeat;

    shape-outside: ellipse(50% 50% at 100% 50%);
  }
  .curved-text {
    flex: 1;
    padding: 20px;
    font-size: 18px;
    background-color: bisque;
   border-radius: 10px;
   text-align: justify;
   line-height: 2.2rem;
  }
  
  .curved-text p {
    margin-bottom: 0;
    text-align: justify;
  }
  .card-title{
    padding: 5px;
    background: #ff512f;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    font-weight: 500;
    font-style: italic;
  }